<template>
  <div class="main-box">
    <el-tabs v-model="tabIdx" @tab-click="chooseTab">
      <el-tab-pane label="基本设置" name="0">
        <el-form :model="info" label-position="right" :rules="rules" ref="ruleForm" label-width="130px" class="ruleForm" size="small" style="max-width: 650px">
          <el-form-item label="开启推客功能" prop="is_open">
            <el-checkbox v-model="info.is_open" true-label="1" false-label="0">开启</el-checkbox>
          </el-form-item>
          <el-form-item label="推客审核" prop="audit_type">
            <el-radio-group v-model="info.audit_type" size="small">
              <el-radio label="1">注册即推客
                <el-tooltip class="item" effect="dark" content="用户注册后自动成为推客" placement="top">
                  <i class="el-icon-warning-outline"></i>
                </el-tooltip>
              </el-radio>
              <el-radio label="2">系统自动审核
                <el-tooltip class="item" effect="dark" content="需要用户申请成为推客，系统自动通过申请" placement="top">
                  <i class="el-icon-warning-outline"></i>
                </el-tooltip>
              </el-radio>
              <el-radio label="3">平台审核
                <el-tooltip class="item" effect="dark" content="用户申请成为推客后，需要后台审核" placement="top">
                  <i class="el-icon-warning-outline"></i>
                </el-tooltip>
              </el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="商品参与类型" prop="goods_type">
            <el-radio-group v-model="info.goods_type" size="small">
              <el-radio label="1">全部商品</el-radio>
              <el-radio label="2">部分商品
                <el-tooltip class="item" effect="dark" content="商品编辑中开启是否参与推广" placement="top">
                  <i class="el-icon-warning-outline"></i>
                </el-tooltip>
                </el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="佣金计算方式" prop="commission_type">
            <el-radio-group v-model="info.commission_type" size="small">
              <el-radio :label="1">按商品实付款
                <el-tooltip class="item" effect="dark" content="不计算商品所有优惠抵扣的金额" placement="top">
                  <i class="el-icon-warning-outline"></i>
                </el-tooltip>
              </el-radio>
              <el-radio :label="2">按商品售价
                <el-tooltip class="item" effect="dark" content="按售卖价格计算佣金" placement="top">
                  <i class="el-icon-warning-outline"></i>
                </el-tooltip>
              </el-radio>
              <el-radio :label="3">按商品成本价
                <el-tooltip class="item" effect="dark" content="按商品产品价格计算佣金" placement="top">
                  <i class="el-icon-warning-outline"></i>
                </el-tooltip>
              </el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="一级佣金比例">
            <el-input v-model="info.commission_first" placeholder="请输入比例">
              <template slot="append">% 0~100</template>
            </el-input>
          </el-form-item>
          <el-form-item label="二级佣金比例">
            <el-input v-model="info.commission_second" placeholder="请输入比例">
              <template slot="append">% 0~100</template>
            </el-input>
          </el-form-item>
          <el-form-item label="收集字段" prop="collect_fields">
            <div v-if="info.collect_fields.length < 5">
              <el-button type="text" @click="addItem()">添加</el-button>
            </div>
            <div class="list" v-if="info.collect_fields && info.collect_fields.length > 0">
              <div class="item mg-bt-10" v-for="(item,index) in info.collect_fields" :key="index">
                <el-row :gutter="10">
                  <el-col :span="9">
                    <el-input v-model="item.title" placeholder="请输入标题"></el-input>
                  </el-col>
                  <el-col :span="9">
                    <el-select v-model="item.type" placeholder="请选择类型">
                      <el-option
                          v-for="item in fieldList"
                          :key="item.type"
                          :label="item.title"
                          :value="item.type">
                      </el-option>
                    </el-select>
                  </el-col>
                  <el-col :span="4">
                    <el-checkbox v-model="item.checked" true-label="1" false-label="0">必填</el-checkbox>
                  </el-col>
                  <el-col :span="1">
                    <el-button type="text" @click="delItem(index)">删除</el-button>
                  </el-col>
                </el-row>
              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
          </el-form-item>
        </el-form>
      </el-tab-pane>
      <el-tab-pane label="推客等级" name="1">
        <guider-grade></guider-grade>
      </el-tab-pane>
      <el-tab-pane label="提现设置" name="2">
        <el-form :model="info" label-position="right" :rules="rules" ref="ruleForm2" label-width="130px" class="ruleForm" size="small" style="max-width: 650px">
          <el-form-item label="提现方式" prop="withdraw_support">
            <el-checkbox-group v-model="info.withdraw_support">
              <el-checkbox :label="1">银行卡
                <el-tooltip class="item" effect="dark" content="银行卡为线下转账" placement="top">
                  <i class="el-icon-warning-outline"></i>
                </el-tooltip>
              </el-checkbox>
              <el-checkbox :label="2">微信
                <el-tooltip class="item" effect="dark" content="企业付款到用户零钱包，需要商户开启企业付款" placement="top">
                  <i class="el-icon-warning-outline"></i>
                </el-tooltip>
              </el-checkbox>
              <el-checkbox :label="3">支付宝
                <el-tooltip class="item" effect="dark" content="支付宝为线下转账" placement="top">
                  <i class="el-icon-warning-outline"></i>
                </el-tooltip>
              </el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="最低提现金额">
            <el-input v-model="info.withdraw_min" placeholder="请输入金额">
              <template slot="append">元，0表示不限制</template>
            </el-input>
          </el-form-item>
          <el-form-item label="提现手续费比例">
            <el-input v-model="info.withdraw_lv" placeholder="请输入比例">
              <template slot="append">% 0~100</template>
            </el-input>
          </el-form-item>
          <el-form-item label="提现银行">
            <el-input type="textarea" rows="6" v-model="info.bank_list" placeholder="请输入银行"></el-input>
            <div class="c999">每行一个银行名称</div>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm('ruleForm2')">保存</el-button>
          </el-form-item>
        </el-form>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>

import variable from "@/utils/variable";
import guiderGrade from "@/components/distribution/guider_grade";

export default {
  data() {
    return {
      tabIdx: 0,
      info: {
        is_open: '',
        commission_type: 1,
        commission_first: '',
        commission_second: '',
        audit_type: '',
        goods_type: '',
        collect_fields: [],
        withdraw_support: [],
        bank_list: '',
        withdraw_min: '',
        withdraw_lv: '',
      },
      fieldList: variable.fieldList,
      rules: {
      }
    };
  },
  created() {
    this.getInfo()
  },
  mounted() {
  },
  computed: {
  },
  components: {
    guiderGrade
  },
  methods: {
    chooseTab(e) {
      this.tabIdx = e.index
    },
    addItem() {
      this.info.collect_fields.push({
        title: '',
        type: '',
        checked: 0
      })
    },
    delItem(index) {
      this.info.collect_fields.splice(index,1)
    },
    getInfo() {
      var _this = this
      this.$api.distribution.guiderSetInfo({}, res => {
        if(res.errcode == 0 && res.data) {
          _this.info = res.data
          _this.info.is_open = (String)(_this.info.is_open)
          _this.info.audit_type = (String)(_this.info.audit_type)
          _this.info.goods_type = (String)(_this.info.goods_type)
          // _this.info.withdraw_support = ['1']
        }
      })
    },
    submitForm(formName) {
      var _this = this
      if(formName=='ruleForm2') {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            var param = _this.info
            this.$api.distribution.guiderWithdrawSetSave(param,function (res) {
              if(res.errcode == 0) {
                _this.success(res.errmsg)
              } else {
                _this.fail(res.errmsg)
              }
            })
          } else {
            return false;
          }
        });
      } else {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            var param = _this.info
            this.$api.distribution.guiderSetSave(param,function (res) {
              if(res.errcode == 0) {
                _this.success(res.errmsg)
              } else {
                _this.fail(res.errmsg)
              }
            })
          } else {
            return false;
          }
        });
      }
    },
  }
};
</script>
<style scoped>
</style>
